.rs__time {
    z-index: 0 !important;
}

#hubspot-messages-iframe-container.widget-align-right {
  display: initial !important;
  z-index: 2147483647;
  position: fixed !important;
  bottom: 56px !important;
}

.chat-widget.mobile {
    height: 90% !important;
    top: 56px !important;
}
#hubspot-messages-iframe-container iframe[data-test-id="chat-widget-iframe"] {
    height: 92% !important;
}
